.test-container {
  background-color: #333;
  color: white;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  border: none;
  cursor: pointer;
}

.test-instruction {
  text-align: center;
  margin-bottom: 20px;
}

.test-instruction h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.test-instruction ul {
  list-style-type: none;
  padding: 0;
}

.test-instruction li {
  font-size: 16px;
  margin-bottom: 10px;
}
