/* components/home/HomePage.css */
.home-page {
    /* Add styles for the home page container */
  }
  
  .hero {
    background-image: url('https://images.unsplash.com/photo-1506748686214-e9df14d4d9d0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
    /* background-image: url('../assets/images/hero.png'); */
    background-size: cover;
    background-position: center;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }
  
  .hero-content {
    max-width: 800px;
    padding: 0 20px;
  }
  
  .hero-title {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    font-size: 24px;
    margin-bottom: 40px;
  }
  
  .cta-button {
    display: inline-block;
    padding: 12px 30px;
    font-size: 18px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .cta-button.primary {
    background-color: var(--primary-color);
    color: white;
  }
  
  .cta-button.secondary {
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    padding: 80px 20px;
  }
  
  .feature {
    max-width: 300px;
    text-align: center;
  }
  
  .feature-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .feature-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .feature-description {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .testimonials {
    background-color: #f5f5f5;
    padding: 80px 20px;
    text-align: center;
  }
  
  .section-title {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .testimonial {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .testimonial-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .testimonial-quote {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .testimonial-author {
    font-size: 16px;
    font-weight: bold;
  }
  
  .cta {
    background-color: var(--primary-color);
    color: white;
    padding: 80px 20px;
    text-align: center;
  }
  
  .cta-title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .cta-description {
    font-size: 18px;
    margin-bottom: 40px;
  }