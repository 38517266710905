.terms-of-service {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.terms-of-service h1 {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 10px;
}

.terms-of-service h2 {
  color: var(--secondary-color);
  margin-top: 30px;
}

.terms-of-service p {
  margin-bottom: 15px;
}

.terms-of-service section {
  margin-bottom: 30px;
}
