/* components/common/Sidebar.css */
.sidebar {
    width: 200px;  /* Reduced from 250px */
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    margin-top: 1rem;
    margin-left: 1rem;
    transition: transform 0.3s ease-in-out;
    font-family: 'Outfit', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sidebar-floating {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    margin: 0;
    border-radius: 0 10px 10px 0;
}

.sidebar-trigger {
    position: fixed;
    left: 0;
    top: 0;
    width: 36px;
    height: 100vh;
}

.sidebar-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  opacity: 0.5;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  border-radius: 50%;
}

.sidebar-toggle:hover {
  opacity: 0.8;
  background-color: rgba(142, 68, 173, 0.05);
}

.sidebar-toggle svg {
  width: 24px;
  height: 24px;
}

/* Rest of the existing CSS */

.sidebar nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar nav ul li:first-child {
    margin-top: 1rem;  /* Added this line */
}

.sidebar nav ul li {
    margin-bottom: 0;  /* Remove bottom margin */
}

.sidebar-link {
    display: block;
    padding: 8px 12px;  /* Reduced padding */
    color: #5a5959;  /* Softer dark gray instead of black */
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;  /* Bold */
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-link:hover {
    background-color: rgba(74, 144, 226, 0.1);  /* Light blue background on hover */
    color: var(--primary-color);
}

.sidebar-link.active {
    background-color: rgba(74, 144, 226, 0.2);  /* Slightly darker blue for active state */
    color: var(--primary-color);
    font-weight: 600;
}

button.sidebar-link {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
}

.sidebar-link.disabled {
    color: #999;
    cursor: not-allowed;
}

.admin-link {
    color: #e74c3c;
}