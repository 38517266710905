.progress-tracking {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.test-type-section {
  margin-bottom: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.test-type-section h3 {
  margin-top: 0;
  color: #333;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.test-results-list {
  list-style-type: none;
  padding: 0;
}

.test-result-item {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 15px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.test-result-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.test-score {
  color: #007bff;
  font-weight: bold;
}

.test-date {
  color: #6c757d;
  font-size: 0.9em;
}

.toggle-details-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-details-btn:hover {
  background-color: #0056b3;
}

.stroop-details {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 15px;
  margin-top: 15px;
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
}

.stroop-details h4 {
  margin-top: 0;
  color: #333;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.stroop-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.metric {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.metric-label {
  font-size: 0.9em;
  color: #6c757d;
  margin-bottom: 5px;
}

.metric-value {
  font-size: 1.1em;
  font-weight: bold;
  color: #28a745;
}

.debug-info {
  margin-top: 20px;
  font-size: 0.8em;
  color: #888;
}
