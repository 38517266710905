.key-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px 0;
  background-color: #f8f9fa; /* Light gray background, you can change this as needed */
}

.key-button {
  display: inline-block;
  width: 36px;
  height: 36px;
  margin: 0 10px;
  background-color: #e0e0e0;
  border: none;
  border-radius: 5px;
  color: #333;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}
  
  .key-button:hover {
    background-color: #e0e0e0;
  }
  
  /* .key-button:active {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
  } */
  
  .key-button-active {
    background-color: #c0c0c0;
  }

  .stimulus-presentation{
    font-size: 65px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-top: 20px;
  }
  .cue-word{
    color: #e6e6e6;
  }

  .feedback{
    color: #e6e6e6;
  }

  /* .stimulus-word {
    opacity: 0;
    transition: opacity 0.5s;
  } */
  
  /* .stimulus-word.fade-in {
    opacity: 1;
  } */

.start-test-button {
  font-size: 24px;
  font-weight: bold;
  color: white;
  background-color: #4CAF50;
  border: none;
  border-radius: 10px;
  padding: 15px 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.start-test-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.start-test-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
