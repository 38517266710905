.cognitive-tests {
 padding: 1rem;
}

.cognitive-tests-title {
 font-size: 2rem;
 color: var(--primary-color);
 margin-bottom: 1rem;
}

.test-card-container {
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
 grid-gap: 1rem;
}
