.admin-dashboard {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-dashboard h2 {
  color: #333;
  margin-bottom: 20px;
}

.admin-tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 2px solid #e0e0e0;
}

.admin-tab {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 5px;
  border-radius: 5px 5px 0 0;
}

.admin-tab:hover {
  background-color: #e0e0e0;
}

.admin-tab.active {
  background-color: #4CAF50;
  color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

tr:hover {
  background-color: #f5f5f5;
}

.role-select {
  padding: 6px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
}

.create-user-form {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
}

.create-user-form h4 {
  margin-top: 0;
  color: #333;
}

.create-user-form input,
.create-user-form select {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.create-user-form button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.create-user-form button:hover {
  background-color: #45a049;
}

.loading, .error, .unauthorized {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-top: 50px;
}

.error {
  color: #d32f2f;
}

.user-management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.admin-button.create {
  background-color: #4CAF50;
  color: white;
}

.admin-button.create:hover {
  background-color: #45a049;
}

.admin-button.cancel {
  background-color: #f44336;
  color: white;
}

.admin-button.cancel:hover {
  background-color: #d32f2f;
}

.test-results-view {
  margin-top: 20px;
}

.test-select {
  margin-bottom: 20px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
