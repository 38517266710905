/* components/common/Header.css */
@import '../../styles/variables.css';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo img {
    height: 40px;
  }
  
  .user-profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  /* components/common/Header.css */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  
  .logo-image {
    height: 40px;
    margin-right: 1rem;
  }
  
  .logo-text {
    font-size: 1.5rem;
    color: var(--primary-color);
  }
  
  .user-profile-text {
    font-size: 1rem;
    color: var(--text-color);
  }

  /* Base styles for the buttons */
.auth-link-login, .auth-link-register {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none; /* Removes underline for links */
}

/* Specific styles for the Login button */
.auth-link-login {
  background-color: transparent; /* Transparent background */
  color: #6c757d; /* Gray color for text */
}

.auth-link-login:hover {
  background-color: #f8f9fa; /* Light background on hover */
  color: #333; /* Darker text on hover */
}

/* Specific styles for the Register button */
.auth-link-register {
  background-color: #6c757d; /* Gray background */
  color: #ffffff; /* White text */
}

.auth-link-register:hover {
  background-color: #5a6268; /* Darker gray background on hover */
  color: #ffffff; /* Keep text white on hover */
}

/* Logout button styles */
.auth-link-logout {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
  background-color: #dc3545;
  color: #ffffff;
}

.auth-link-logout:hover {
  background-color: #c82333;
}

/* User avatar styles */
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
