/* App.css */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Open Sans', sans-serif;
}

.app-container {
  display: flex;
  flex: 1;
  padding: 1rem;
  transition: margin-left 0.3s ease-in-out;
}

.app-container.sidebar-closed {
  margin-left: -250px;
}

.main-content {
  flex: 1;
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: 1rem;
  margin-top: 1rem;
  transition: margin-left 0.3s ease-in-out;
}

.main-content.sidebar-closed {
  margin-left: 1rem;
}