.profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.profile h3 {
  font-size: 1.5rem;
  color: var(--secondary-color);
  margin-bottom: 0.5rem;
}

.profile p {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.profile ul {
  list-style-type: none;
  padding: 0;
}

.profile li {
  background-color: #f9f9f9;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--text-color);
  border-radius: 5px;
}

.btn-edit, .btn-save {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: white;
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}

.btn-save {
  background-color: var(--secondary-color);
}

.btn-logout {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
}

.btn-logout:hover {
  background-color: var(--tertiary-color);
  color: var(--background-color);
}

.profile-container {
  display: flex;
  flex-direction: column;
  min-height: 50vh; /* This ensures the container takes up at least the full viewport height */
}

.profile-content {
  flex-grow: 1; /* This allows the content to grow and push the footer down */
}

.profile-footer {
  margin-top: auto;
  padding: 1rem 2rem; /* Increased left padding */
  text-align: left; /* Changed from center to left */
}
