/* components/auth/LoginForm.css */
.login-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .login-form-title {
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    font-size: 1rem;
    color: var(--text-color);
    margin-bottom: 0.5rem;
  }
  
  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid var(--text-color);
    border-radius: 5px;
  }
  
  .btn-login {
    display: block;
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    color: white;
    background-color: var(--primary-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .signup-link {
    margin-top: 10px;
    font-size: 0.9em;
    text-align: center;
  }

  .signup-link a {
    color: #007bff;
    text-decoration: none;
  }

  .signup-link a:hover {
    text-decoration: underline;
  }

  .error-message {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 0.9em;
    line-height: 1.4;
  }

  .success-message {
    color: #28a745;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 0.9em;
    line-height: 1.4;
  }

  .btn-login:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }