.test-card {
 background-color: white;
 border-radius: 10px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 padding: 1rem;
 margin-bottom: 1rem;
 cursor: pointer;
 transition: transform 0.3s;
}

.test-card:hover {
 transform: translateY(-5px);
}

.test-card-title {
 color: var(--primary-color);
 font-size: 1.2rem;
 margin-bottom: 0.5rem;
}

.test-card-description {
 color: var(--text-color);
 font-size: 1rem;
}

.test-card.disabled {
  opacity: 0.5;
}
